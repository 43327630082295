import { flightType } from "./flightType/en";
import { flightClass } from "./flightClass/en";
import { priceDisplayType } from "./priceDisplayType/en";
import { utilityType } from "./utilityType/en";
import { chatTabTypes } from "./chatTabTypes/en";
import { searchType } from "./searchType/en";
import { searchTypeShort } from "./searchTypeShort/en";
import { masterMessageTabTypes } from "./masterMessageTabTypes/en";
import { reservationTypes } from "./reservationTypes/en";
import { searchColumnType } from "./searchColumnType/en";
import { hotelTabTypes } from "./hotelTabTypes/en";
import { hotelSortType } from "./hotelSortType/en";
import { hotelMealType } from "./hotelMealType/en";
import { hotelBedSizeType } from "./hotelBedSizeType/en";
import { flightSortType } from "./flightSortType/en";
import { flightReservedSeatRequestType } from "./flightReservedSeatRequestType/en";
import { optionalTourCategories } from "./optionalTourCategories/en";
import { searchMealType } from "./searchMealType/en";
import { hotelViewType } from "./hotelViewType/en";
import { hotelSmokingTypes } from "./hotelSmokingTypes/en";
import { hotelRoomAmenities } from "./hotelRoomAmenities/en";
import { hotelAmenities } from "./hotelAmenities/en";
import { hotelCategories } from "./hotelCategories/en";
import { hotelSpokenLanguages } from "./hotelSpokenLanguages/en";
import { flightFreeBaggageType } from "./flightFreeBaggageType/en";
import { flightArrangementType } from "./flightArrangementType/en";
import { passengerType } from "./passengerType/en";
import { serviceType } from "./serviceType/en";
import { flightOptionType } from "./flightOptionType/en";
import { allianceType } from "./alllianceType/en";
import { country } from "./country/en";
import { countryCode } from "./countryCode/en";
import { contractTypes } from "./contractTypes/en";
import { airportCode } from "./airportCode/en";
import { masterMessageGroupType } from "./masterMessageGroupType/en";
import { crmMessageScheduleConditionType } from "./crmMessageScheduleConditionType/en";
import { seo } from "./seo/en";
import { en as crmMessageScheduleStatus } from "./crmMessageScheduleStatus/en.yml";
import { en as inHouseNoticeTaskType } from "./inHouseNoticeTaskType/en.yml";
import { en as inHouseNoticeTaskDescription } from "./inHouseNoticeTaskDescription/en.yml";
import { en as lpType } from "./lpType/en.yml";
import { en as lpLayoutType } from "./lpLayoutType/en.yml";
import { en as lpStatus } from "./lpStatus/en.yml";
import { en as externalArrangementType } from "./externalArrangementType/en.yml";
import { en as postStatus } from "./postStatus/en.yml";
import { en as vesselAttractionAttire } from "./vesselAttractionAttire/ja.yml";
import { en as vesselAttractionCategory } from "./vesselAttractionCategory/ja.yml";
import { en as vesselAttractionCuisineType } from "./vesselAttractionCuisineType/ja.yml";
import { en as vesselCabinCategory } from "./vesselCabinCategory/ja.yml";
import { en as cruiseType } from "./cruiseType/ja.yml";

export const constantEn = {
  flightType,
  flightClass,
  priceDisplayType,
  utilityType,
  chatTabTypes,
  searchType,
  searchTypeShort,
  masterMessageTabTypes,
  reservationTypes,
  searchColumnType,
  hotelTabTypes,
  hotelSortType,
  hotelMealType,
  hotelBedSizeType,
  flightSortType,
  flightReservedSeatRequestType,
  optionalTourCategories,
  searchMealType,
  hotelViewType,
  hotelSmokingTypes,
  hotelRoomAmenities,
  hotelAmenities,
  hotelCategories,
  hotelSpokenLanguages,
  flightFreeBaggageType,
  flightArrangementType,
  passengerType,
  serviceType,
  flightOptionType,
  allianceType,
  country,
  countryCode,
  contractTypes,
  airportCode,
  masterMessageGroupType,
  crmMessageScheduleConditionType,
  seo,
  crmMessageScheduleStatus,
  inHouseNoticeTaskType,
  inHouseNoticeTaskDescription,
  lpType,
  lpLayoutType,
  lpStatus,
  externalArrangementType,
  postStatus,
  vesselAttractionAttire,
  vesselAttractionCategory,
  vesselAttractionCuisineType,
  vesselCabinCategory,
  cruiseType,
};
