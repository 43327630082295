export const search: any = {
  hotel: "ホテル",
  flight: "フライト",
  overseasflight: "海外フライト",
  overseasFlightHotel: "海外フライト\n+ ホテル",
  search: "検 索",
  update: "更新",
  close: "閉じる",
  apply: "適用",
  cancel: "キャンセル",
  searchResults: "{{totalNum}}件ヒット",
  searchResultsShort: "{{totalNum}}件",
  searchResultsPage:
    "検索結果: {{startNum}} - {{endNum}} 件 / {{totalNum}} 件中",
  filter: "絞り込み",
  map: "地図",
  confirm: "確認する",
  done: "完了",
  selectDate: "{{dateName}}を選択してください",
  calendarMaxDaysMessage: "最大日数を超えたため再選択してください",
  popularDestinations: "人気の旅行先",
  searchingCruises: "クルーズを検索中...",
  searchingCruiseCabins: "キャビンを検索中...",
  searchingHotels: "ホテルを検索中...",
  searchingFlights: "フライトを検索中...",
  cruise: "クルーズ",
};
