import { common } from "./common/en";
import { en as consultation } from "./consultation/en.yml";
import { en as reservation } from "./reservation/en.yml";
import { en as cruise } from "./cruise/en.yml";

export const buttonEn: any = {
  common,
  reservation,
  consultation,
  cruise,
};
