import { reservation } from "./reservation/en";
import { common } from "./common/en";
import { consultationCondition } from "./consultationCondition/en";
import { flight } from "./flight/en";
import { hotel } from "./hotel/en";
import { formError } from "./formError/en";
import { cms } from "./cms/en";
import { en as form } from "./form/en.yml";
import { en as emailUser } from "./emailUser/en.yml";
import { en as consultation } from "./consultation/en.yml";
import { en as cruise } from "./cruise/en.yml";

export const labelEn: any = {
  reservation,
  common,
  consultationCondition,
  flight,
  hotel,
  formError,
  cms,
  form,
  emailUser,
  consultation,
  cruise,
};
