import { flightType } from "./flightType/ja";
import { flightClass } from "./flightClass/ja";
import { flightSortTypeForConsultation } from "./flightSortType/ja";
import { flightReservedSeatRequestType } from "./flightReservedSeatRequestType/ja";
import { hotelSortTypeForConsultation } from "./hotelSortType/ja";
import { hotelMealType } from "./hotelMealType/ja";
import { hotelViewType } from "./hotelViewType/ja";
import { hotelBedSizeType } from "./hotelBedSizeType/ja";
import { searchMealType } from "./searchMealType/ja";
import { companionType } from "./companionType/ja";
import { passengerType } from "./passengerType/ja";
import { flightFreeBaggageType } from "./flightFreeBaggageType/ja";
import { cityCode } from "./cityCode/ja";
import { postStatus } from "./postStatus/ja";
import { searchType } from "./searchType/ja";
import { seo } from "./seo/ja";
import { ja as platformType } from "./platformType/ja.yml";
import { ja as region } from "./region/ja.yml";
import { ja as vesselAttractionAttire } from "./vesselAttractionAttire/ja.yml";
import { ja as vesselAttractionCategory } from "./vesselAttractionCategory/ja.yml";
import { ja as vesselAttractionCuisineType } from "./vesselAttractionCuisineType/ja.yml";
import { ja as vesselCabinCategory } from "./vesselCabinCategory/ja.yml";
import { ja as cruiseCabinConsultationType } from "./cruiseCabinConsultationType/ja.yml";

export const constantJa: any = {
  flightType,
  flightClass,
  flightSortTypeForConsultation,
  flightReservedSeatRequestType,
  hotelSortTypeForConsultation,
  hotelMealType,
  hotelViewType,
  hotelBedSizeType,
  searchMealType,
  companionType,
  passengerType,
  flightFreeBaggageType,
  cityCode,
  postStatus,
  searchType,
  seo,
  platformType,
  region,
  vesselAttractionAttire,
  vesselAttractionCategory,
  vesselAttractionCuisineType,
  vesselCabinCategory,
  cruiseCabinConsultationType,
};
