export const common: any = {
  checkin: "Checkin",
  checkout: "Checkout",
  checkinDate: "Checkin date",
  checkoutDate: "Checkout date",
  departureDate: "Departing",
  returnDate: "Returning",
  bookingClass: "Preferred class",
  destination: "Going to",
  origin: "Flying from",
  flightNumberOfAdult: "Adults(12+)",
  flightNumberOfChildren: "Children(0-11)",
  flightAgesOfChildren: "Age Of Children {{index}}",
  stopCount: "Stop count",
  departureTime: "Departure time",
  arrivalTime: "Arrival time",
  totalPrice: "Total price",
  airport: "Airport",
  unspecified: "Unspecified",
  flightNonstop: "Nonstop",
  flightStopUpTo: "Up to {{count}} stops",
  outboundCity: "Outbound",
  returnCity: "Return",
  departureCity: "City {{index}}",
  lastArrivalCity: "Last arrival city",
  price: "¥{{price}}",
  minMaxRange: "{{min}} ~ {{max}}",
  airportCity: "City {{index}}",
  cheapestFirst: "Cheapest",
  fastestFirst: "Fastest",
  recommended: "Recommended",
  addItinerary: "+ Add flight",
  removeItinerary: "- Remove flight",
  itinerary: "Itinerary",
  date: "Date",
  traveler: "Traveler",
  adultNum_one: "{{count}} adult",
  adultNum_other: "{{count}} adults",
  childNum_one: "{{count}} child",
  childNum_other: "{{count}} children",
  selectionCriteria: "Selection Criteria",
  none: "None",
  hotelRank: "Hotel grade",
  people: "Number of people",
  fromTotalPrice: "From the total price",
  flight: "Flight",
  flightEmail: "Flight",
  hotel: "Hotel",
  hotelEmail: "Hotel",
  request: "Request",
  recommendedPoints: "Recommended points",
  planContents: "Plan contents",
  rating: "Rating",
  pricePerPerson: "Price per person",
  common: "Common",
  hours: "hour",
  hours_other: "hours",
  useThis: "Use this",
  select: "Select",
  selectThisFlight: "Select this flight",
  or: "or",
  region: "Region",
  roomsLeft_one: "{{count}} room left",
  roomsLeft_other: "{{count}} rooms left",
  cancelFree: "There is no cancellation charge",
  book: "Book",
  detail: "Detail",
  showDetail: "Show detail",
  optional: "Optional",
  arrangementFee: "Arrangement fee",
  lteStayNum: "{{stayNum}} night or less",
  unselected: "Unselected",
  home: "home",
  orMore: "or more",
  evaluation: "Evaluation",
  required: "Required",
  numberOfAdults: "Adults",
  numberOfChildren: "Children",
};
