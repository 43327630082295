export const common: any = {
  total: "合計",
  loading: "読み込み中",
  clickToSelect: "クリックして選択",
  viewLargerMap: "拡大地図を表示",
  others: "その他",
  notSet: "未設定",
  email: "メール",
  sortBy: "並べ替え",
  unspecified: "未指定",
};
