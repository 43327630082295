export const common: any = {
  checkin: "チェックイン",
  checkout: "チェックアウト",
  checkinDate: "チェックイン日",
  checkoutDate: "チェックアウト日",
  departureDate: "出発日",
  returnDate: "現地出発日",
  bookingClass: "座席の種類",
  destination: "目的地",
  origin: "出発地",
  flightNumberOfAdult: "大人(12歳以上)",
  flightNumberOfChildren: "子供(0～11歳)",
  flightAgesOfChildren: "お子様 {{index}}の年齢",
  durationOver1Hour: "{{hour}}時間{{minute}}分",
  durationUnder1Hour: "{{minute}}分",
  numberOfConnections_one: "直行便",
  numberOfConnections_other: "乗換{{count}}回",
  stopCount: "経由回数",
  departureTime: "出発時刻",
  arrivalTime: "到着時刻",
  totalPrice: "合計価格",
  airport: "空港",
  unspecified: "指定なし",
  flightNonstop: "直行便",
  flightStopUpTo: "{{count}} 回以下",
  outboundCity: "出発地",
  returnCity: "現地出発地",
  departureCity: "出発地 {{index}}",
  lastArrivalCity: "最終到着地",
  price: "{{price}}円",
  minMaxRange: "{{min}} ~ {{max}}",
  airportCity: "都市 {{index}}",
  cheapestFirst: "価格が低い順",
  fastestFirst: "時間が短い順",
  recommended: "おすすめ順",
  addItinerary: "+ 区間を追加",
  removeItinerary: "- 区間を削除",
  itinerary: "区間",
  date: "日付",
  traveler: "旅行者",
  adultNum_one: "大人 {{count}}名",
  adultNum_other: "大人 {{count}}名",
  childNum_one: "お子様 {{count}}名",
  childNum_other: "お子様 {{count}}名",
  selectionCriteria: "選択基準",
  none: "無し",
  hotelRank: "ホテルグレード",
  people: "人数",
  fromTotalPrice: "合計料金から",
  flight: "フライト",
  flightEmail: "フライト",
  hotel: "ホテル",
  hotelEmail: "ホテル",
  request: "希望内容",
  recommendedPoints: "おすすめポイント",
  planContents: "プラン内容",
  rating: "評価",
  pricePerPerson: "一人当たりの料金",
  common: "共通",
  hours: "時間",
  hours_other: "時間",
  useThis: "利用する",
  select: "選択する",
  selectThisFlight: "このフライトを選択する",
  or: "または",
  region: "地域",
  roomsLeft_one: "残り {{count}}部屋",
  roomsLeft_other: "残り {{count}}部屋",
  cancelFree: "キャンセル料無料",
  book: "予約する",
  detail: "詳細",
  showDetail: "詳細を見る",
  optional: "オプション",
  arrangementFee: "手配手数料",
  lteStayNum: "{{stayNum}}泊以下",
  unselected: "未選択",
  home: "ホーム",
  serviceType: "タイプ",
  breakdown: "内訳",
  orMore: "以上",
  evaluation: "評価",
  required: "必須",
  numberOfAdults: "大人",
  numberOfChildren: "子供",
};
