export const common: any = {
  total: "Total",
  loading: "Loading",
  clickToSelect: "Click to select",
  others: "Others",
  viewLargerMap: "View larger map",
  notSet: "Not set",
  email: "email",
  sortBy: "Sort by",
  undefined: "Unspecified",
};
