export const unitJa: any = {
  durationOver1Hour: "{{hour}}時間{{minute}}分",
  durationUnder1Hour: "{{minute}}分",
  numberOfConnections_zero: "直行便",
  numberOfConnections_one: "乗換{{count}}回",
  numberOfConnections_other: "乗換{{count}}回",
  days: "{{count}}日",
  grade1: "一ツ星",
  grade2: "二ツ星",
  grade3: "三ツ星",
  grade4: "四ツ星",
  grade5: "五ツ星",
  people: "{{num}}人",
  person_one: "{{count}}名",
  person_other: "{{count}}名",
  yearsOld: "{{num}}歳",
  rooms: "{{num}}室",
  cabins: "{{count}}室",
  bed_one: "{{type}}ベッド {{count}}台",
  bed_other: "{{type}}ベッド {{count}}台",
  sofaBed_one: "{{type}}ソファベッド {{count}}台",
  sofaBed_other: "{{type}}ソファベッド {{count}}台",
  futon_one: "{{type}}布団 {{count}}組",
  futon_other: "{{type}}布団 {{count}}組",
  startsAt: "{{value}}/1名 〜",
  nights_one: "{{count}}泊",
  nights_other: "{{count}}泊",
  numberOfAdults_one: "大人 {{count}}名",
  numberOfAdults_other: "大人 {{count}}名",
  numberOfChildren_one: "子供 {{count}}名",
  numberOfChildren_other: "子供 {{count}}名",
  rangeOfAges: "{{min}} - {{max}}才",
};
