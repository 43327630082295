export const chat = {
  pleaseSelectUser: "ユーザーを選択してください",
  searchTotalNum: "合計{{totalNum}}件",
  flightConnecting: "乗り継ぎ",
  flightTicketPrice: "航空券代金",
  arrangeServiceCharge: "手数料",
  totalPrice: "合計料金",
  flightPriceDetail: "航空券代金内訳",
  hotelPriceDetail: "ホテル代金内訳",
  grade: "グレード：{{grade}}\n",
  rating: "口コミ評価：{{rating}} / 5.0\n{{ratingText}}\n",
  rating40Text: "（評価が高い✨）",
  rating45Text: "（評価最高ランク🌟）",
  rating50Text: "（評価最高ランク🎖）",
  arrangementCancelTitle: "キャンセル？",
  arrangementCancelDescription: "キャンセルを実行しますか？",
  searchExpirationTitle: "最新情報をチェック",
  searchExpirationDescription:
    "最新の情報を表示するため、検索結果を更新する必要があります。",
  searchRefresh: "更新する",
  reservationExpirationTitle: "セッションの有効期限切れ",
  reservationExpirationDescription:
    "この予約は有効期限切れになりました。再度検索してください。",
  reservationResearch: "再検索する",
  reservationOutOfStockTitle: "商品の在庫切れ",
  reservationOutOfStockDescription:
    "この商品は完売になりました。再度検索してください。",
  reservationPriceChangedTitle: "料金が変更になりました",
  reservationNewPriceConfirmationDescription:
    "この商品の料金が変更になりました。\n新しい料金は\n{{newPrice}}(差額: {{priceDifference}})\nです。予約を続行しますか？",
  reservationConfirmNewPriceButton: "続行",
  reservationPriceChangedDescription:
    "この商品の料金が変更になったため、ページを更新する必要があります。",
  reservationRefresh: "更新する",
  aboutCancellation: "キャンセルについて",
  cancellationPolicy: "キャンセル不可または情報がありませんでした。",
  memoTemplateUseAlertDescription: "テンプレートを使用しますか？",
  refundTitle: "返金を行います",
  refundDescription: "下記を入力してください",
  partialRefundTitle: "一部返金を行います",
  partialRefundDescription: "返金額を下記に入力してください",
  correctPriceTitle: "支払料金の入力",
  commissionBackTitle: "コミッションバック",
  textSelectionInstruction: "登録するテキストを\n下記より範囲選択してください",
  saveAsNewCondition: "追加で新規作成",
  annotationFormPlaceholder: "メッセージのテキストを範囲選択してください",
  messageForSearchData: "検索データ対象メッセージ",
  selectMessage: "メッセージを選択してください",
  consultationContent: "相談内容",
  hopeContent: "希望内容",
  reservationSummary: "予約概要",
  searchCondition: "フライト検索条件",
  hotelSearchCondition: "ホテル検索条件",
  section: "区間",
  date: "日付",
  traveler: "旅行者",
  origin: "出発地",
  destination: "到着地",
  restoreSearch: "この条件で再検索",
  numberOfAdults: "大人{{number}}名",
  numberOfChildren: "子供{{number}}名",
  agesOfChildren: "子供の年齢",
  originSummary: "出発地：{{origin}}",
  destinationSummary: "目的地：{{destination}}",
  departureDateSummary: "出発日: {{date}}",
  returnDateSummary: "現地出発日: {{date}}",
  unsent: "未送信",
  sentDate: "送信日時",
  noFlightData: "フライトデータ無し",
  noHotelData: "ホテルデータ無し",
  hotelPrice: "ホテル代金",
  questionSpecifiedMode: "相談指定中",
  setReminderTitle: "リマインダー設定",
  setReminderDescription: "日時とメモを入力してください",
  setScheduledSendingTitle: "時間指定送信設定",
  setScheduledSendingDescription: "日時を入力してください",
  departFrom: "{{name}}出発",
  fromTotalPrice: "合計料金から",
  selectRecommendedSentences: "おすすめ文を選択してください",
  autoSuggestionSuccessMessage: "提案を作成中です。後ほど更新してください。",
  saveReservationOnlyNotice:
    "予約情報のみDBに保存されます。手配は別途行ってください。",
  reservationpartiallyFailedTitle: "後続の手配に失敗しました",
  reservationpartiallyFailedDescription:
    "複数の手配の途中でエラーが発生しました。予約内容を確認してください。",
  goToReservation: "予約詳細ページへ移動する",
  unknownErrorTitle: "不明なエラー",
  unknownErrorDescription: "不明なエラーが発生しました",
  filterByThisHotel: "このホテルでフィルタ",
  filterByThisFlight: "このフライトでフィルタ",
  reserveThisFlight: "このフライトを予約する",
  reserveThisHotel: "このホテルを予約する",
  hasSelectedRegions: "選択された地域あり",
  refundableCancellationPolicy: "出発前まで一部返金可能。",
  noCancellation: "キャンセル不可",
  cancellable: "キャンセル可能",
  cancellationFee: "キャンセル料",
  totalRefundAmount: "合計返金額",
  totalCancellationPenalty: "合計キャンセル料",
  cannotReservation: "現在予約ができません(予約情報だけ作成は可能です)",
  displayAfterSave: "追加後に表示されます",
  autoCostCalculatedEditTitle: "自動料金計算を変更します",
  autoCostCalculatedEditDescription:
    "自動で料金計算をすると自動対応できることが増えます。\nやり方がわからない場合はSlackで相談してください。",
  autoCostCalculatedEditButton: "それでも編集する",
  flightPreReservation: "フライトの予約",
  hotelPreReservation: "ホテルの予約",
  adjustPriceTitle: "料金の変更",
  addPriceTitle: "料金の追加",
  addRefundTitle: "返金の登録",
  hotelSpecialRequestNotice: "※ 半角英数字(日本語不可) (Ex) Extra bed 1",
  confirmReservationTitle: "予約を確定します",
  confirmReservationDescription: "* 予約がファイナルされ、請求が発生します",
  confirmReservationWithNoCancellationDescription:
    "* 予約がファイナルされ、請求が発生します\n* キャンセル不可です",
  selectOptionalTourPlanDateTitle: "指定日のツアー用メッセージを作成します",
  selectOptionalTourPlanDateDescription: "日付と参加人数を選択してください",
  notAvailable: "利用可能ではありません",
  outOfRange: "指定可能な範囲外です",
  optionalTourFeeNotice: "※ たびチャット手配の場合は上記に+1,100円となります",
  optionalTourLinkText: "詳細はこちら",
  optionalTourIncludingPrice: "(合計料金に含まれます)\n",
  optionalTourExcludingPrice: "(合計料金に含まれません)\n",
  optionalTourTitle: "🚩オプショナルツアー\n",
  showInVeltra: "VELTRAで表示する",
  preReservationMemo: "予約メモについて",
  createCostMessage: "料金付きメッセージ作成",
  recommendedPlanSummary: "おすすめプランの概要",
  suggestionContents: "たびチャットご提案内容",
  imageCreationMessage: "画像を作成中です。数分で完了します。",
  itineraries: "旅程",
  splitItineraries: "分割旅程",
  showOriginalPrice: "元の料金を見る",
  cruiseDetail: "クルーズ詳細",
};
