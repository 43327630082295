export const common = {
  flightArrangement: "フライト手配",
  flightArrangementNew: "フライト手配作成",
  hotelArrangement: "ホテル手配",
  hotelArrangementNew: "ホテル手配作成",
  optionalTourArrangement: "オプショナルツアー手配",
  optionalTourArrangementNew: "オプショナルツアー手配作成",
  rentalCarArrangement: "レンタカー手配",
  rentalCarArrangementNew: "レンタカー手配作成",
  wifiArrangement: "WiFi手配",
  wifiArrangementNew: "WiFi手配作成",
  railwayArrangement: "鉄道手配",
  railwayArrangementNew: "鉄道手配作成",
  cruiseArrangement: "クルーズ手配",
  cruiseArrangementNew: "クルーズ手配作成",
  insuranceArrangement: "保険手配",
  insuranceArrangementNew: "保険手配作成",
  otherArrangement: "その他手配",
  otherArrangementNew: "その他手配作成",
  discount: "割引",
  discountNew: "割引作成",
  deleteMemo: "メモの削除",
  deleteMemoDescription: "メモを削除します。よろしいですか？",
  sendAllMessages: "メッセージの一括送信",
  sendAllMessagesDescription: "全てのメッセージを送信します。よろしいですか？",
  refundBankTransferNotice:
    "手動で銀行返金を完了させてください。\n手数料も込みの返金額を入力してください。",
  calendarMaxDaysMessage: "最大日数を超えたため再選択してください",
  sendMessage: "メッセージの送信",
  sendMessageDescription:
    "通常は[全て送信]ボタンを使用するようにしてください。\nメッセージを送信しますか？",
  flightArrangementInvoiceTitle: "フライト手配を請求済みに変更",
  flightArrangementArrangeTitle: "フライト手配を仮予約中に変更",
  flightArrangementCancelTitle: "フライト手配のキャンセル",
  flightArrangementVoidTitle: "フライト手配の発券キャンセル",
  flightArrangementTicketingTitle: "フライト手配を発券依頼中に変更",
  flightArrangementWaitingForTicketedTitle: "フライト手配を発券待ちに変更",
  flightArrangementTicketedTitle: "フライト手配を発券済みに変更",
  flightArrangementRefundingTitle: "フライト手配を返金依頼中に変更",
  flightArrangementRefundedTitle: "フライト手配を返金済みに変更",
  changeStatus: "ステータス変更",
  defaultRefundedAmountToUserValue:
    "サプライヤーからの返金額から返金手数料を引いて返金します",
  deleteAllLabeledDataSets: "作成中メッセージの全削除",
  deleteAllLabeledDataSetsDescription:
    "全ての作成中メッセージを削除します。よろしいですか？",
  includeCodeshare: "コードシェア便あり",
  hotelArrangementInvoiceTitle: "ホテル手配を請求済みに変更",
  hotelArrangementArrangeTitle: "ホテル手配を仮予約中に変更",
  hotelArrangementCancelTitle: "ホテル手配のキャンセル",
  hotelArrangementCancelNotice: "外部サイトの予約がキャンセルされます。",
  hotelArrangementConfirmedTitle: "ホテル手配を確定済みに変更",
  hotelArrangementConfirmingTitle: "ホテル手配を確定予定に変更",
  hotelArrangementConfirmedNotice:
    "外部サイトも予約確定され、支払いが発生します。",
  hotelArrangementConfirmingNotice:
    "期限になったら自動的に外部サイトの予約が確定されます。",
  hotelArrangementRefundingTitle: "ホテル手配を返金依頼中に変更",
  hotelArrangementRefundedTitle: "ホテル手配を返金済みに変更",
  hotelArrangementRefundedNotice: "外部サイトの予約がキャンセル/返金されます。",
  selectAllTravelers: "全ての旅行者を選択",
  selectAllPlans: "全ての部屋を選択",
  optionalTourArrangementInvoiceTitle: "オプショナルツアー手配を請求済みに変更",
  optionalTourArrangementArrangeTitle: "オプショナルツアー手配を仮予約中に変更",
  optionalTourArrangementCancelTitle: "オプショナルツアー手配のキャンセル",
  optionalTourArrangementWaitingForConfirmationTitle:
    "オプショナルツアー手配を催行待ちに変更",
  optionalTourArrangementConfirmedTitle:
    "オプショナルツアー手配を確定済みに変更",
  optionalTourArrangementRefundingTitle:
    "オプショナルツアー手配を返金依頼中に変更",
  optionalTourArrangementRefundedTitle:
    "オプショナルツアー手配を返金済みに変更",
  railwayArrangementInvoiceTitle: "鉄道手配を請求済みに変更",
  railwayArrangementArrangeTitle: "鉄道手配を仮予約中に変更",
  railwayArrangementCancelTitle: "鉄道手配のキャンセル",
  railwayArrangementWaitingForArrangementTitle: "鉄道手配を手配待ちに変更",
  railwayArrangementConfirmedTitle: "鉄道手配を確定済みに変更",
  railwayArrangementRefundingTitle: "鉄道手配を返金依頼中に変更",
  railwayArrangementRefundedTitle: "鉄道手配を返金済みに変更",
  selectDate: "{{dateName}}を選択してください",
  customerTransferFeeNotice:
    "※ 振込手数料がお客様負担の場合に手数料を記入してください",
  sendConfirmation: "確認情報の送信",
  sendCharge: "請求情報の送信",
  submitFormError: "入力フォームでエラーが発生しました",
  submitError: "エラーが発生しました",
  regionGreat: "とてもおすすめ",
  regionGood: "おすすめ",
  regionOthers: "その他",
  total: "合計",
  questionCondition: "旅行相談",
  none: "なし",
  forOperator: "オペレーター向け",
  changeDeadlineTitle: "期限の変更",
  registerExternalPrice: "外部料金を登録",
  webLink: "{{text}}サイト",
  discountPrice: "{{price}}割引",
  discountPriceOn: "{{name}}で{{price}}割引",
  price: "料金",
  arrangeFee: "手数料",
  totalPrice: "合計料金",
  loading: "読み込み中",
  clickToSelect: "クリックして選択",
  estimatedPrice: "概算料金",
};
