import { common } from "./common/ja";
import { ja as consultation } from "./consultation/ja.yml";
import { ja as reservation } from "./reservation/ja.yml";
import { ja as cruise } from "./cruise/ja.yml";

export const buttonJa: any = {
  common,
  reservation,
  consultation,
  cruise,
};
